import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import toppic02 from "/src/images/top-pic-02.png"

const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]

  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("センター紹介")} lang={lang}/>
      <ContentLayout lang={lang}>
        <h1>{t("センター紹介")}</h1>
        <img src={ toppic02 } alt=""  className="img-wide" />
        <h2 id="anchor01">{t("バイオリソース研究センター")}</h2>
        <p>
          {t("世界のバイオリソース（生物遺伝資源）に関する中核的拠点として、研究動向を的確に把握し、社会ニーズ・研究ニーズに応え、世界最高水準のバイオリソースを収集・保存し、提供する事業を実施しています。また、バイオリソースの利活用に資する研究開発を推進しています。")}
        </p>
        <p className="txt-center">
          <External 
            href={"https://"+t("web.brc.riken.jp/ja/")}
            btn="2"
            >
              {t("バイオリソース研究センター")}
          </External>
        </p>

      
      </ContentLayout>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`